<template>
    <div class="myPatient" :style="myPatient">
        <van-cell-group inset class="patientList m0" :style="patientList">
            <van-cell
                class="bgNo"
                to="/doctor/patient/treatmentBreakdown"
                center
                title-class="totalTitle"
                value-class="integral"
                :title="'您的邀请患者数总计：' + patients.length + '人'"
            >
                <template #extra>
                    <span class="tplCopy">治疗明细</span
                    ><van-icon :name="iconRight" class="iconRight" />
                </template>
            </van-cell>
            <van-cell
                v-for="(item, i) in patients"
                :key="i"
                class="bgNo"
                center
                title-class="friendName"
                value-class="registrationDate"
                :title="item.name"
                :value="'注册日期：' + formatDay(item.createdAt)"
            >
                <template #icon>
                    <van-image :src="item.wxHeadimgurl || userPhoto" width="48" />
                </template>
                <template #title>
                    <span>{{ item.name }}</span>
                    <van-icon v-if="item.sex === 1" :name="sexNan" class="sex-icon" />
                    <van-icon v-if="item.sex === 2" :name="sexNv" class="sex-icon" />
                    <van-icon v-if="item.hasDevice" :name="phone" class="phone-icon" />
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
import { getPatients } from '@/services/doctor';
import { toast,formatDate } from '@/util';

export default {
    // name: "index"
    data() {
        return {
            patients: [],
            myPatient: {
                backgroundImage: 'url(' + require('@/assets/images/myBgReverse.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
            },
            patientList: {
                backgroundImage: 'url(' + require('@/assets/images/fridendListBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '50% 50%',
                backgroundPosition: '115px 35px',
            },
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            phone: require('@/assets/images/phoneIcon.png'),
            iconRight: require('@/assets/images/iconRight.png'),
        };
    },
    created() {
        this.getPatients();
    },
    methods: {
        async getPatients() {
            const { code, message, data } = await getPatients();
            if (!code) {
                return toast(message);
            }
            this.patients = data;
        },
        formatDay(day) {
            if (!day) {
                return '';
            }
            return formatDate(day, 'yyyy-MM-dd');
        },
    },
};
</script>

<style scoped>
.myPatient{
    padding: 10px;
    box-sizing: border-box;
}
.totalTitle {
    font-size: 14px;
    color: #282a2d;
}
.integral {
    font-size: 14px;
    color: #58ae9f;
}
.patientList .van-cell__left-icon ,.patientList .van-image{
    font-size: 48px;
    height: auto;
    line-height: 100%;
    margin-right: 10px;
    border-radius: 5px;
    overflow: hidden;
}
.friendName {
    font-size: 12px;
    color: #282a2d;
    margin-block: 10px;
}
.registrationDate {
    font-size: 10px;
    color: #868686;
}
.sex-icon,
.phone-icon {
    font-size: 14px;
    margin-left: 5px;
}
.iconRight {
    font-size: 10px;
    margin-left: 5px;
}
.patientList .van-cell__title,
.patientList .van-cell__value {
    -webkit-box-flex: auto;
    -webkit-flex: auto;
    flex: auto;
}
.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
}
</style>
